<template>
  <div class="list-info">
    <top-bar title="年龄预警服务" :left="true"></top-bar>
    <div class="filterBar">
      <van-row>
        <van-col span="24">
          <p
            @click="labelTypeShow = !labelTypeShow"
            :style="{ color: labelTypeShow ? '#387FF5' : '#666666' }"
          >
            {{ labelTypeTitle }}
            <img
              :src="
                require(`@/assets/img/${
                  labelTypeShow ? 'pull-down-select' : 'pull-down'
                }.png`)
              "
              alt=""
              class="pull-icon"
            />
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="labelTypeShow" position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="typeOptions"
        value-key="label"
        @confirm="labelTypeConfirm"
        @cancel="labelTypeShow = false"
      />
    </van-popup>

    <div class="cont">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getDataList"
        v-if="dataList.length > 0"
        offset="10"
      >
        <div class="totalCount">
          共有&nbsp;<span style="color: #387ff5">{{ totalCount }}</span
          >&nbsp;条数据
        </div>
        <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
          <div class="title">
            <div v-if="item.houseName" class="title-left">
              {{ item.houseName
              }}<span style="color: #387ff5">/{{ item.serviceName }}</span>
            </div>
            <div v-else class="title-left">未入住</div>
            <div class="title-right">
              <a :href="'tel:' + item.mobile" @click.stop=""
                ><img src="@/assets/img/phone.png" alt="" class="tel"
              /></a>
            </div>
          </div>
          <div class="content">
            <img
              v-if="item.sex == 1"
              :src="require('@/assets/img/headImgMan.png')"
              class="content-img"
            />
            <img
              v-else
              :src="require('@/assets/img/headImgWoman.png')"
              class="content-img"
            />
            <div class="content-text">
              <div class="top-text">
                {{ item.name }}/<span style="color: #999999">{{
                  item.sex == 1 ? "男" : "女"
                }}</span>
              </div>
              <div class="bottom-text" style="color: #999999">
                {{ item.mobile }}
              </div>
              <div class="bottom-text" style="color: #999999">
                {{ item.idNumber }}
              </div>
            <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
          <div style="height: 40px">
            <van-row class="edit-buttom">
              <van-col :span="5"></van-col>
              <van-col :span="7">
                <van-button
                  round
                  plain
                  size="small"
                  type="info"
                  :loading="loading"
                  @click="igNore(item.id)"
                  >忽略</van-button
                >
              </van-col>
              <van-col :span="5"></van-col>
              <van-col :span="7">
                <van-button
                  round
                  plain
                  size="small"
                  type="info"
                  :loading="loading"
                  @click="Apply(item.id)"
                  >申请</van-button
                >
              </van-col>
            </van-row>
          </div>
        </div>
      </van-list>
      <van-empty description="没有数据哦" v-if="dataList.length < 1" />
    </div>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
export default {
  components: {
    topBar,
  },
  data() {
    return {
      labelTypeShow: false,
      finished: false,
      dataForm: {
        page: 0,
        limit: 10,
        orgId: "",
        labelId: "",
        serviceId: "",
        type: "",
      },
      labelTypeTitle: "服务名称",
      typeOptions: [],
      warningTypes: [],
      totalCount: 0,
      dataList: [],
    };
  },
  methods: {
    getDataList() {
      this.dataForm.page++;
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/commonApp/agedService/applyList"),
        method: "post",
        params: this.$http.adornParams(this.dataForm),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            console.log(data);
            this.totalCount = data.page.totalCount;
            if (this.dataList.length == data.page.totalCount) {
              this.finished = true;
            }else {
              this.dataList = this.dataList.concat(data.page.list);
            }

            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    getServiceType() {
      this.$http({
        url: this.$http.adornUrl(
          "/wxapp/commonApp/agedService/agedServiceType"
        ),
        method: "post",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let list = data.type;
          let typeList = [];
          list.forEach((e) => {
            let serviceId = String(e.serviceId);
            typeList.push({
              value: serviceId,
              label: e.serviceName,
              labelId: e.labelId,
            });
          });
          this.typeOptions = typeList;
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    // 服务类型选择
    labelTypeConfirm(value) {
      if (value) {
        this.labelTypeTitle = value.label;
        this.dataForm.type = value.value;
        this.dataForm.serviceId = value.value;
        this.dataForm.labelId = value.labelId;
      }
      this.labelTypeShow = false;
      this.dataForm.page = 0;
      this.getDataList();
    },
    // 忽略
    igNore(e) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认忽略",
        })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(
              "/wxapp/commonApp/agedService/agedServiceType"
            ),
            method: "post",
            data: this.$http.adornData({
              community: this.$orgId + "",
              labelId: this.dataForm.labelId + "",
              serviceId: this.dataForm.serviceId + "",
              userId: e + "",
              type: "2",
            }),
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.page = 0;
              this.getDataList();
            } else {
              this.$toast.fail(data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 申请
    Apply(e) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认申请",
        })
        .then(() => {
          // on confirm
          this.$http({
            url: this.$http.adornUrl(
              "/wxapp/commonApp/agedService/saveService"
            ),
            method: "post",
            data: this.$http.adornData({
              community: this.$orgId + "",
              labelId: this.dataForm.labelId + "",
              serviceId: this.dataForm.serviceId + "",
              userId: e + "",
              type: "1",
            }),
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.page = 0;
              this.getDataList();
            } else {
              this.$toast.fail(data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    getInfo(id) {
      this.$router.push({ path: "/userResInfo", query: { userId: id } });
    },
  },
  created() {
    this.dataForm.type = this.$route.query.type || "";
    this.dataForm.serviceId = this.$route.query.type || "";
    this.dataForm.orgId = this.$orgId;
    this.getServiceType();
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped>
.list-info {
  font-size: 24px;
  overflow: hidden;
  .search {
    background-color: #fff;
    width: 750px;
    padding: 20px 30px 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    .search-content {
      width: 552px;
      height: 60px;
      border-radius: 40px;
      border: 1px solid #e8e8e8;
      padding-left: 30px;
      position: relative;
      &::placeholder {
        color: #999999;
      }
    }
    .search-clear {
      width: 22px;
      height: 22px;
      position: absolute;
      right: 200px;
      top: 40px;
    }
    .search-btn {
      width: 122px;
      line-height: 60px;
      text-align: center;
      border-radius: 60px;
      color: #fff;
      background-color: #387ff5;
    }
  }
  .filterBar {
    text-align: center;
    line-height: 100px;
    font-size: 24px;
    .pull-icon {
      width: 10px;
      display: inline-block;
    }
    .dateBar {
      font-size: 24px;
    }
  }
  .addBtn {
    position: fixed;
    right: 0;
    bottom: 200px;
    z-index: 999;
    width: 161px;
    height: 161px;
  }
  .cont {
    .totalCount {
      margin: 0;
      color: #333;
      padding: 20px 28px !important;
      background-color: transparent;
    }
    .list-item {
      border-radius: 10px;
      background-color: #fff;
      margin: 20px 28px 0;
      padding: 0 24px;
      &:nth-child(2) {
        margin-top: 0;
      }
      .edit-buttom {
        width: 100%;
        // margin-top: 20;
      }
      .title {
        height: 70px;
        border-bottom: 1px solid #ebf2fe;
        .title-left {
          float: left;
          line-height: 70px;
          color: #666666;
          width: 420px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .title-right {
          float: right;
          line-height: 70px;

          // width: 30px;
          // height: 30px;
          // color: #387ff5;
          .tel {
            width: 48px;
            height: 48px;
          }
        }
      }
      .content {
        height: 148px;
        display: flex;
        align-items: center;
        position: relative;
        .content-img {
          width: 100px;
          height: 100px;
        }
        .content-text {
          margin-left: 40px;
          .top-text {
            color: #333333;
            font-size: 30px;
            width: 450px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .bottom-text {
            color: #666;
            font-size: 26px;
            margin-top: 0px;
            width: 450px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .right-text {
            color: #387ff5;
            font-size: 26px;
            position: absolute;
            top: 30px;
            right: 28px;
          }
          .up-text {
            color: #666;
            font-size: 26px;
            margin-top: 20px;
            width: 420px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .content-icon {
          position: absolute;
          width: 30px;
          height: 30px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .content2 {
        height: 188px;
        display: flex;
        align-items: center;
        position: relative;
        .content-img {
          width: 110px;
          height: 150px;
          img {
            margin-left: 8px;
            width: 100px;
            height: 100px;
          }
          .content-downLoad {
            color: #387ff5;
            font-size: 26px;
            width: 100%;
            margin: 20px 0;
            text-align: center;
          }
        }
        .content-text {
          margin-left: 40px;
          .top-text {
            color: #333333;
            font-size: 30px;
            width: 370px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              color: #999;
              font-size: 24px;
            }
          }
          .center-text {
            color: #999;
            font-size: 24px;
            margin-top: 20px;
            width: 370px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .right-text {
            color: #387ff5;
            font-size: 26px;
            position: absolute;
            top: 30px;
            right: 28px;
          }
          .up-text {
            color: #666;
            font-size: 26px;
            margin-top: 20px;
            width: 420px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .content-icon {
          position: absolute;
          right: 0;
          top: 50%;
          width: 30px;
          height: 30px;
          transform: translateY(-50%);
        }
        .content-btns {
          position: absolute;
          top: 0;
          right: 20px;
          width: 100px;
          .content-btn {
            margin-top: 20px;
            .van-button {
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
}
</style>
